<template>
  <div data-cy="employee-login-page" class="auth-content d-flex justify-content-center">
    <div class="auth-form">
      <h3 class="font-weight-bolder text-dark font-size-h1 font-size-h1-lg mb-10">
        {{ $t("title.auth.login") }}
      </h3>

      <b-form data-cy="login-form" novalidate @submit.stop.prevent="onSubmitForm">
        <InputElement
          v-model="form.email"
          class="mb-10"
          size="lg"
          input-type="email"
          :with-error="errors.email"
          :error-message="emailErrorMsg"
          data-cy="email-input"
          :label="$t('label.email')"
          @blur="removeErrorClass('email')"
          @input="removeErrorClass('email')"
        />

        <InputElement
          v-model="form.password"
          class="mb-8"
          size="lg"
          input-type="password"
          :with-error="errors.password"
          :error-message="passwordErrorMsg"
          data-cy="password-input"
          :label="$t('label.password._')"
          @blur="removeErrorClass('password')"
          @input="removeErrorClass('password')"
        />

        <div class="d-flex align-items-center flex-wrap">
          <button
            data-cy="submit-btn"
            type="submit"
            class="btn auth-btn submit-btn btn-primary font-weight-bolder font-size-h6 px-15 py-4"
          >
            {{ $t("button.login") }}
          </button>

          <b-link
            data-cy="forgot-password"
            class="auth-btn forgot-link d-block text-primary font-size-h6 font-weight-bolder text-hover-primary"
            :to="forgotPasswordRoute"
          >
            {{ $t("page.login.forgotPassword") }}
          </b-link>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import user from "@/configs/user.config.json";
import InputElement from "@/components/form/InputElement";

export default {
  name: "EmployeeLogin",
  components: {
    InputElement,
  },

  data: () => ({
    userType: user.employee,
    form: {
      email: "",
      password: "",
    },
    passwordMinLength: 8,
    errors: {
      email: false,
      password: false,
    },
  }),

  computed: {
    emailErrorMsg() {
      return !this.form.email ? this.$t("validation.required") : this.$t("validation.mustBeEmail");
    },

    passwordErrorMsg() {
      return !this.form.password
        ? this.$t("validation.required")
        : this.$t("validation.passwordMinLength", { length: this.passwordMinLength });
    },

    forgotPasswordRoute() {
      return { name: "EmployeeForgotPassword" };
    },
  },

  methods: {
    ...mapActions(["login"]),

    removeErrorClass(key) {
      this.errors[key] = false;
    },

    isValidForm() {
      const emailRegExp = /.+@.+\..+/i;

      for (let key in this.errors) {
        this.errors[key] = false;
      }

      this.errors.email = !this.form.email || !this.form.email.match(emailRegExp);
      this.errors.password =
        !this.form.password || !(this.form.password.length >= this.passwordMinLength);

      return !Object.values(this.errors).some((error) => error);
    },

    async onSubmitForm() {
      if (!this.isValidForm()) return;

      const formData = {
        email: this.form.email,
        password: this.form.password,
        rememberMe: true,
        userType: this.userType,
      };

      await this.login(formData);
    },
  },
};
</script>
