<template>
  <div data-cy="employee-forgot-password-page" class="auth-content d-flex justify-content-center">
    <div class="auth-form">
      <div class="mb-11 pt-lg-0">
        <h3 class="font-weight-bolder text-dark font-size-h1 font-size-h1-lg">
          {{ $t("page.forgotPassword._") }}
        </h3>

        <p class="text-muted font-weight-bold font-size-h4">
          {{ $t("page.forgotPassword.description") }}
        </p>
      </div>

      <b-form data-cy="reset-password-form" novalidate @submit.stop.prevent="onClickSubmit">
        <InputElement
          v-model="form.email"
          data-cy="email-input"
          class="mb-8"
          size="lg"
          required
          input-type="email"
          :with-error="errors.email"
          :error-message="emailErrorMsg"
          :label="$t('label.email')"
          @blur="removeErrorClass"
          @input="removeErrorClass"
        />

        <div class="d-flex align-items-center flex-wrap">
          <b-button
            data-cy="submit-btn"
            type="submit"
            variant="primary"
            class="auth-btn submit-btn font-weight-bolder font-size-h6 px-15 py-4"
          >
            {{ $t("button.resetPassword") }}
          </b-button>

          <b-link
            data-cy="cancel-btn"
            class="btn auth-btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
            :to="employeeLoginRoute"
          >
            {{ $t("button.back") }}
          </b-link>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import InputElement from "@/components/form/InputElement";
import storage from "@/services/storage.service";

export default {
  name: "EmployeeForgotPassword",
  components: {
    InputElement,
  },

  data: () => ({
    form: {
      email: "",
    },
    errors: {
      email: false,
    },
  }),

  computed: {
    emailErrorMsg() {
      return !this.form.email ? this.$t("validation.required") : this.$t("validation.mustBeEmail");
    },

    employeeLoginRoute() {
      return { name: "EmployeeLogin" };
    },
  },

  methods: {
    ...mapActions(["resetPassword"]),

    removeErrorClass() {
      this.errors.email = false;
    },

    isValidForm() {
      const emailRegExp = /.+@.+\..+/i;

      this.errors.email = !this.form.email || !this.form.email.match(emailRegExp);

      return !this.errors.email;
    },

    async onClickSubmit() {
      if (!this.isValidForm()) return;

      const formData = {
        email: this.form.email,
      };

      const response = await this.resetPassword(formData);

      storage.setObj("notify", { type: "success", message: response.data.message });

      this.$router.push(this.employeeLoginRoute);
    },
  },
};
</script>
